import styled from 'styled-components';

export const DesktopHistorySearch = styled.button`
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-size: 14px;
    color: #5452dd;
    margin-left: 8px;

    @media (max-width: 959px) {
        display: none;
    }
`;

export const MobileHistorySearch = styled.button`
    display: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-size: 14px;
    color: #5452dd;
    margin-left: 8px;

    @media (max-width: 959px) {
        display: block;
    }
`;

export const Routes = styled.div`
    font-weight: bold;
    cursor: pointer;
    display: flex;
    font-size: 18px;
    color: #404b5a;

    @media (max-width: 599px) {
        font-size: 14px;
    }
`;

export const DialogContent = styled.div`
    padding: 0;
`;

export const MainHistoryBlock = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
`;

export const SearchBlockInfo = styled.div`
    color: #404b5a !important;
`;

export const SearchedTime = styled.div`
    display: flex;
    margin-bottom: 8px;
`;

export const TimeSpan = styled.div`
    margin-left: 6px;
`;

export const SearchButtons = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
`;

export const OpenBrowserButton = styled.button`
    background-color: #5452dd;
    height: 30px;
    color: #fff;
    font-weight: inherit;
    font-size: 12px;
    cursor: pointer;
    border: none;
    width: 40%;
    text-transform: uppercase;
    border-radius: 4px;
    font-weight: bold;
`;

export const ClearButton = styled.button`
    background-color: #e4e9f3;
    margin-left: 3px;
    height: 30px;
    color: #d60c0c;
    font-weight: inherit;
    font-size: 12px;
    cursor: pointer;
    border: none;
    width: 40%;
    text-transform: uppercase;
    border-radius: 4px;
    font-weight: bold;
`;

export const LoadingBlock = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`;

export const Footer = styled.div`
    display: flex;
    justify-content: center;
    margin: 16px;
    position: fixed;
    bottom: 4px;
    width: 88%;
`;

export const ClearAllHistoryButton = styled.button`
    background-color: #e4e9f3;
    color: #fff;
    font-weight: inherit;
    font-size: 14px;
    color: #d60c0c;
    height: 40px;
    width: 35%;
    text-transform: uppercase;
    font-weight: bold;
    border: none;
    border-radius: 8px;
`;

export const NoResultText = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`;

export const SearchBlock = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-radius: 16px;
    background-color: #fff;
    border: 1px solid #e4e7e8;
    margin-bottom: 16px;

    @media (max-width: 599px) {
        padding: 8px;
    }
`;

export const RoutesBlock = styled.div`
    display: flex;
    flex-direction: column;
`;
