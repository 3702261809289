import React, { useMemo } from 'react';
import TooltipUI from '../../../ui/components/TooltipUI/TooltipUI';
import useLocalization from '../../../../contexts/localization/hook';

import { ReactComponent as ReturnIcon } from '../../../../assets/icons/newDesign/returnIcon.svg';
import { ReactComponent as ExchangeIcon } from '../../../../assets/icons/newDesign/exchangeIcon.svg';
import { ReactComponent as BaggageIcon } from '../../../../assets/icons/newDesign/baggageIcon.svg';
import { ReactComponent as HandLuggageIcon } from '../../../../assets/icons/newDesign/handLuggageIcon.svg';
import { Wrapper, Icon } from './components';

const FlightInfoIcons = ({ data }) => {
    const { t } = useLocalization();
    const { is_refund, is_change, baggage, hand_luggage } = data;

    const getColor = status => {
        return status ? '#5452dd' : '#b9c1c5';
    };

    const returnIcon = useMemo(() => {
        const title = is_refund ? t.yes_refund : t.no_refund;

        return (
            <TooltipUI title={title}>
                <Icon style={{ color: getColor(is_refund) }}>
                    <ReturnIcon />
                </Icon>
            </TooltipUI>
        );
    }, [is_refund, getColor]);

    const exchangeIcon = useMemo(() => {
        const title = is_change ? t.yes_change : t.no_change;

        return (
            <TooltipUI title={title}>
                <Icon style={{ color: getColor(is_change) }}>
                    <ExchangeIcon />
                </Icon>
            </TooltipUI>
        );
    }, [is_change, getColor]);

    const baggageIcon = useMemo(() => {
        const title = baggage ? t.yes_baggage : t.no_baggage;

        return (
            <TooltipUI title={title}>
                <Icon style={{ color: getColor(baggage) }}>
                    <BaggageIcon />
                </Icon>
            </TooltipUI>
        );
    }, [baggage, getColor]);

    const handLuggageIcon = useMemo(() => {
        const title = hand_luggage ? t.yes_hand_luggage : t.no_hand_luggage;

        return (
            <TooltipUI title={title}>
                <Icon style={{ color: getColor(hand_luggage) }}>
                    <HandLuggageIcon />
                </Icon>
            </TooltipUI>
        );
    }, [hand_luggage, getColor]);

    return (
        <Wrapper>
            {returnIcon}
            {exchangeIcon}
            {baggageIcon}
            {handLuggageIcon}
        </Wrapper>
    );
};

export default FlightInfoIcons;
