import styled from 'styled-components';

export const Component = styled.div`
    display: flex;
    flex-direction: column;
`;

export const InputBlock = styled.div`
    display: flex;
    align-items: center;
    height: 60px;
    width: 100%;
    border: 1px solid ${props => (props.error ? '#ff0000' : '#c3d6e8')};
    border-radius: 12px;
    padding: 0 10px;
    background-color: #fff;
`;

export const Label = styled.div`
    font-size: 16px;
    color: #404b5a;
    margin-right: 8px;
    font-weight: bold;

    & > span {
        color: #ff0000;
    }
`;

export const Input = styled.input`
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 16px;
    flex-grow: 1;
    height: 100%;
`;

export const ErrorText = styled.div`
    color: #ff0000;
    font-size: 14px;
    margin-top: 4px;
`;
