import styled from 'styled-components';
import { ArrowUpward } from '@material-ui/icons';

export const Button = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;

    position: fixed;
    right: 24px;
    bottom: 24px;
    z-index: 100;

    border-radius: 50%;
    border: none;
    width: 56px;
    height: 56px;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);

    transition: all 0.2s;
    transform: scale(0);
    opacity: 0;

    &.active {
        transform: scale(1);
        opacity: 1;
    }

    &:hover {
        cursor: pointer;
        background-color: #5452dd;

        & > svg {
            color: #fff;
        }
    }

    @media (max-width: 959px) {
        display: none;
    }
`;

export const ButtonIcon = styled(ArrowUpward)`
    color: #969696;
`;
