import React, { Fragment } from 'react';
import Routes from './routes';
import Footer from './components/design_v2/footer';
import { AppContainer } from './styles/common/index';
import Header from './components/design_v2/header';
import MainPopup from './components/design_v2/mainPopup';

import './App.css';

const App = () => {
    return (
        <Fragment>
            <AppContainer id="AppContainer">
                <Header />
                <Routes />
            </AppContainer>
            <Footer />
        </Fragment>
    );
};

export default App;
