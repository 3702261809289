import styled from 'styled-components';

export const SegmentsWrapper = styled.div`
    padding: 24px 16px 16px;
    background-color: #fff;
`;

export const TransferBetweenAirportWarning = styled.div`
    padding-left: 16px;
    color: #ff531d;
`;

export const SegmentBlock = styled.div`
    display: flex;
    flex-direction: column;
`;

export const TransferBlock = styled.div`
    display: flex;
    align-items: center;
    padding: 8px 0;
    margin: 16px 0;
`;

export const TransferTime = styled.div`
    color: #9da8ae;
    margin-left: 8px;
    line-height: 1;
`;

export const SegmentInfo = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 16px;
`;

export const AirlineLogo = styled.div`
    height: 30px;
    width: 90px;
    min-width: 90px;
    background-image: url(${props => props.imgUrl});
    background-repeat: no-repeat;
    background-position: center center;
`;

export const SegmentInfoText = styled.div`
    font-size: 14px;
    line-height: 1.2;
    margin-left: 12px;
`;

export const SegmentDate = styled.div`
    font-size: 20px;
    font-weight: bold;
    line-height: 1;
    margin-bottom: 16px;
`;

export const SegmentTimes = styled.div`
    display: flex;
    flex-direction: column;
`;

export const SegmentDepartureBlock = styled.div`
    display: flex;
    align-items: center;
`;

export const SegmentDepartureTime = styled.div`
    box-sizing: border-box;
    flex: 0 0 120px;
    padding-left: 40px;
    position: relative;

    & > .circle {
        background-color: #fff;
        border: 2px solid #5452dd;
        border-radius: 50%;
        height: 8px;
        left: 8px;
        position: absolute;
        width: 8px;
        z-index: 2;
        top: 8px;
    }

    & > .line {
        background-color: #5452dd;
        bottom: 0;
        left: 11px;
        position: absolute;
        top: 10px;
        width: 2px;
    }

    & > .text {
        font-size: 20px;
        font-weight: bold;
    }
`;

export const SegmentDepartureInfo = styled.div`
    line-height: 1;
    font-size: 14px;

    & > span {
        color: #9da8ae;
        font-weight: bold;
    }
`;

export const SegmentDuration = styled.div`
    height: 30px;
    display: flex;
    align-items: center;
    line-height: 1;
    box-sizing: border-box;
    padding-left: 120px;
    position: relative;
    font-size: 14px;
    text-transform: lowercase;

    & > .line {
        background-color: #5452dd;
        bottom: 0;
        left: 11px;
        position: absolute;
        top: 0;
        width: 2px;
    }

    & > .onWay {
        color: #9da8ae;
    }

    & .time {
        color: #000;
    }
`;

export const SegmentArrivalBlock = styled.div`
    display: flex;
    align-items: center;
`;

export const SegmentArrivalTime = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    flex: 0 0 120px;
    padding-left: 40px;
    position: relative;

    & > .circle {
        background-color: #fff;
        border: 2px solid #5452dd;
        border-radius: 50%;
        height: 8px;
        left: 8px;
        position: absolute;
        width: 8px;
        z-index: 2;
        bottom: 8px;
    }

    & > .line {
        background-color: #5452dd;
        top: 0;
        left: 11px;
        position: absolute;
        bottom: 10px;
        width: 2px;
    }

    & > .otherDate {
        font-size: 14px;
        line-height: 1;
    }

    & > .text {
        font-size: 20px;
        font-weight: bold;
    }
`;

export const SegmentArrivalInfo = styled.div`
    line-height: 1;
    font-size: 14px;
    margin-top: ${props => props.hasMarginTop ? '14px' : 0};

    & > span {
        color: #9da8ae;
        font-weight: bold;
    }
`;
