import React, { useState } from 'react';
import { Container, CircularProgress, Grid } from '@material-ui/core';
import useLocalization from '../../../../../contexts/localization/hook';
import API from '../../../../../lib/api';

import {
    SubscriptionBlock,
    Icon,
    EmailBlock,
    PlainText,
    InputEmail,
    Input,
    Button,
    P,
    Inform,
    IconForMobile,
    ResultBlock,
} from './components';
import messageIcon from '../../../../../assets/icons/message.svg';
import { useStyles } from '../../styles';
import LineDivider from '../lineDivider';

const SubscriptionToNewsletter = () => {
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [result, setResult] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const classes = useStyles();
    const { t } = useLocalization();

    const sendData = () => {
        if (validateEmail()) {
            setEmailError(false);
            setIsLoading(true);
            API.postSubscribe({ email })
                .then(res => {
                    setResult('success');
                    setIsLoading(false);
                })
                .catch(err => {
                    setResult('error');
                });
        } else {
            setEmailError(true);
        }
    };

    const validateEmail = () => {
        const re =
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    };

    return (
        <>
            <div style={{ backgroundColor: '#fff', width: '100%' }}>
                <Container fixed>
                    <SubscriptionBlock>
                        <Icon src={messageIcon} />
                        <EmailBlock>
                            <PlainText>
                                <P>{t.newsletter_subscription}</P>
                                <div style={{ display: 'flex' }}>
                                    <Inform>{t.newsletter_subscription_text}</Inform>
                                    <IconForMobile src={messageIcon} />
                                </div>
                            </PlainText>

                            <InputEmail>
                                <Input value={email} onChange={e => setEmail(e.target.value)} placeholder="Email" />
                                <Button disabled={isLoading} onClick={() => sendData()}>
                                    {isLoading ? (
                                        <CircularProgress size={26} className={classes.circularProgress} />
                                    ) : (
                                        t.subscribe
                                    )}
                                </Button>
                            </InputEmail>

                            <ResultBlock>
                                {result && (
                                    <Grid item xs={12}>
                                        {result === 'success' && (
                                            <span className={classes.successResult}>{t.successfully_subscribed}</span>
                                        )}
                                        {result === 'error' && (
                                            <span className={classes.errorResult}>{t.subscription_error}</span>
                                        )}
                                    </Grid>
                                )}
                                {emailError && (
                                    <Grid item xs={12}>
                                        <span className={classes.errorResult}>{t.invalid_email}!</span>
                                    </Grid>
                                )}
                            </ResultBlock>
                        </EmailBlock>
                    </SubscriptionBlock>
                </Container>
            </div>

            <LineDivider />
        </>
    );
};

export default SubscriptionToNewsletter;
