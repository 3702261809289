import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Navigation = styled.div`
    display: flex;
    justify-content: space-between;
    width: 500px;
    margin: 50px auto;

    @media (max-width: 599px) {
        justify-content: space-around;
        width: 100%;
        border-bottom: 1px solid #d2d2d2;
        margin: 30px 0;
    }
`;

export const NavigationLink = styled(({ active, ...props }) => <Link {...props} />)`
    padding: 15px 25px;
    font-size: 28px;
    color: ${props => (props.active ? '#5452dd' : '#404b5a')};
    text-decoration: none;
    border-bottom: ${props => (props.active ? '2px solid #5452dd' : 'none')};
    font-weight: bold;

    &:hover {
        color: #5452dd;
        border-bottom: 2px solid #5452dd;
    }

    @media (max-width: 1279px) {
        font-size: 24px;
    }

    @media (max-width: 959px) {
        font-size: 18px;
        border-bottom: none;

        &:hover {
            border-bottom: none;
        }
    }
`;

export const Form = styled.div`
    display: flex;
    flex-direction: column;
    width: 500px;
    margin: 0 auto 100px;

    @media (max-width: 959px) {
        width: 100%;
    }
`;

export const FormBlock = styled.div`
    display: flex;
    flex-direction: column;
    width: 500px;

    @media (max-width: 959px) {
        width: 100%;
    }
`;

export const FormTitle = styled.div`
    font-size: 50px;
    color: #404b5a;
    text-align: center;
    font-weight: bold;

    @media (max-width: 1279px) {
        font-size: 36px;
    }

    @media (max-width: 959px) {
        font-size: 24px;
    }
`;

export const FormSubtitle = styled.div`
    font-size: 20px;
    color: #404b5a;
    text-align: center;
    margin-bottom: 30px;
    font-weight: bold;

    @media (max-width: 1279px) {
        font-size: 16px;
    }
`;

export const FormGroup = styled.div`
    width: 100%;
    max-width: 380px;
    margin: 0 auto 30px;
`;

export const FormSubmitButton = styled.button`
    margin: ${props => (props.marginAdd ? '20px auto' : '20px auto')};
    height: ${props => (props.heightAdd ? '60px' : '70px')};
    min-width: ${props => props.widthSize || '184px'};
    background-color: #5452dd;
    border: none;
    border-radius: 12px;
    font-size: 28px;
    font-weight: bold;
    color: #fff;
    cursor: pointer;
    display: block;

    @media (max-width: 959px) {
        margin-top: 30px;
        height: 60px;
        font-size: 24px;
    }
`;

export const Socials = styled.div``;

export const SocialButton = styled.button``;

export const MobileBlock = styled.div`
    display: none;

    @media (max-width: 599px) {
        display: block;
    }
`;

export const DesktopBlock = styled.div`
    @media (max-width: 599px) {
        display: none;
    }
`;

export const ForgotPasswordLink = styled(Link)`
    color: #5452dd;
    font-size: 16px;
    font-weight: inherit;
    text-decoration: none;
`;
