import styled from 'styled-components';

export const DropdownHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 16px;
    height: 62px;
`;

export const DropdownHeaderTitle = styled.div`
    font-weight: bold;
    color: #7574cd;
    width: 100%;
`;

export const DropdownHeaderButton = styled.button`
    color: #fff;
    border: none;
    padding: 0 8px;
    border-radius: 6px;
    font-weight: bold;
    font-size: 0.8rem;
    cursor: pointer;
    background-color: #5452dd;
    height: 30px;
    width: 140px;

    &:hover {
        background-color: #1662a7;
    }
`;

export const DropdownHeaderTitle2 = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
    font-weight: bold;
    color: #9eb3c7;
    width: 100%;
`;

export const OnlyThereButton = styled.button`
    display: flex;
    position: sticky;
    bottom: 0px;
    font-weight: bold;
    font-size: 16px;
    line-height: 1;
    letter-spacing: 1px;
    color: #fff;
    padding: 16px 32px;
    margin: 16px auto 0;
    background-color: #FFC20E;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);
    border: none;
    border-radius: 8px;
    cursor: pointer;
`;
