import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import LoadingForBlock from './containers/loadingForms/loadingForBlock';
import configs from './config';

import HomeContainer from './containers/home';
import ProfileInfo from './containers/userProfile';
import ResultContainer from './containers/result';
import BookingContainer from './containers/booking';
import OrderContainer from './containers/order';
import OrdersContainer from './containers/orders';
import RegistrationContainer from './containers/registration';
import LoginContainer from './containers/login';
import TransactionsContainer from './containers/transactions';
import PopularDestinationsContainer from './containers/popularDestinations';

import ForgotPassword from './pages/forgotPassword';
import ResetPassword from './pages/resetPassword';
import LastNewsPage from './pages/lastNews';
import SinglePageNews from './pages/singleNews';
import TourDiscountPage from './pages/toursAndDiscount';
import StaticInfoPage from './pages/staticPages';
import RechargingBalancePage from './pages/chargingBalancePage';
import Partners from './pages/ourPartners';
import FaqPage from './pages/faq';
import unSubscribe from './pages/unsubscribe';

const nonAuthRoutes = configs.nonAuthRoutes;
const authRoutes = configs.authRoutes;

const Routes = () => {
    return (
        <Suspense fallback={<LoadingForBlock />}>
            <Switch>
                <Route exact path={nonAuthRoutes.registration} component={RegistrationContainer} />
                <Route exact path={nonAuthRoutes.login} component={LoginContainer} />
                <Route exact path={nonAuthRoutes.forgotPassword} component={ForgotPassword} />
                <Route exact path={nonAuthRoutes.resetPassword} component={ResetPassword} />
                <Route exact path="/result/:searchId" component={ResultContainer} />
                <Route exact path="/" component={HomeContainer} />
                <Route exact path={authRoutes.userProfile} component={ProfileInfo} />
                <Route exact path={authRoutes.booking} component={BookingContainer} />
                <Route exact path={authRoutes.order} component={OrderContainer} />
                <Route exact path={authRoutes.orders} component={OrdersContainer} />
                <Route exact path={authRoutes.transactions} component={TransactionsContainer} />
                <Route exact path={authRoutes.newsPage} component={LastNewsPage} />
                <Route exact path={authRoutes.singleNews} component={SinglePageNews} />
                <Route exact path={authRoutes.toursPage} component={TourDiscountPage} />
                <Route exact path={authRoutes.staticPages} component={StaticInfoPage} />
                <Route exact path={authRoutes.rechargeBalancePage} component={RechargingBalancePage} />
                <Route exact path={authRoutes.partners} component={Partners} />
                <Route exact path={nonAuthRoutes.popularDestinations} component={PopularDestinationsContainer} />
                <Route exact path={nonAuthRoutes.usefulInfo} component={FaqPage} />
                <Route exact path={nonAuthRoutes.unSubscribe} component={unSubscribe} />
            </Switch>
        </Suspense>
    );
};

export default Routes;
