import { Container } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import {
    Component,
    HideOnMobile,
    HideOnPc,
    Block,
    MainBlock,
    Column,
    LogoBlock,
    ContactUsText,
    ContactsEmail,
    ContactPhone,
    LinkToDownload,
    LinkToStaticPage,
    SocialsBlock,
    SocialsLinks,
    SocialLink,
    DontCopyText,
    PaymentMethods,
    PaymentMethodsText,
    PaymentMethodsLogos,
    PaymentMethodLogo,
    LogoImage,
} from './components';
import useLocalization from '../../../contexts/localization/hook';

import { Telegram as TelegramIcon } from '@material-ui/icons';
import { ReactComponent as FlyLogoWhite } from '../../../assets/icons/newDesign/flyLogoWhite.svg';
import { ReactComponent as FacebookIcon } from '../../../assets/icons/newDesign/facebookIcon.svg';
import { ReactComponent as InstagramIcon } from '../../../assets/icons/newDesign/instagramIcon.svg';
import { ReactComponent as WhatsAppIcon } from '../../../assets/icons/newDesign/whatsAppIcon.svg';
import dipLogo from '../../../images/mainLogo/dip.webp';
import kortiMilliLogo from '../../../images/otherLogos/kort.png';
import API from '../../../lib/api';
import envConfigs from '../../../config/envConfigs';

const Footer = () => {
    const { t, language } = useLocalization();
    const [state, setState] = useState([]);

    useEffect(() => {
        staticInfoDiscriptions();
    }, []);

    const staticInfoDiscriptions = async () => {
        await API.getStaticDiscriptions().then(res => {
            setState(res.data);
        });
    };

    const divideBlocks = (array, chunks) => {
        let result = [];
        let n = state.length;

        for (let i = 0; i < n; i += chunks) {
            result = [...result, array.slice(i, i + chunks)];
        }

        return result;
    };

    let columns = divideBlocks(state, 6);

    return (
        <Component>
            <Container fixed maxWidth="lg">
                <Block>
                    <MainBlock>
                        <Column>
                            <HideOnMobile>
                                <LogoBlock>
                                    <LogoImage src={dipLogo} alt="dip logo" />
                                    {/* <FlyLogoWhite /> */}
                                </LogoBlock>
                            </HideOnMobile>

                            <ContactUsText>{t.contact_us}:</ContactUsText>
                            <ContactsEmail>
                                {t.customer_support}: {envConfigs.supportPhone}
                            </ContactsEmail>
                            <ContactsEmail>
                                {t.technical_support}: {envConfigs.saleMail}
                            </ContactsEmail>
                            <ContactPhone>
                                {t.tel}: {envConfigs.supportPhone}
                            </ContactPhone>
                        </Column>

                        {columns.map((items, key) => {
                            return (
                                <Column key={`footer-list${key}`}>
                                    {key === 0 && (
                                        <LinkToStaticPage to={`/useful-info`}>
                                            {t.useful_info || 'Полезная информация'}
                                        </LinkToStaticPage>
                                    )}

                                    {items.map((item, index) => {
                                        const contentByLanguage = item.footer_content.find(
                                            content => content.language === language
                                        );

                                        if (contentByLanguage?.attachment) {
                                            return (
                                                <LinkToDownload
                                                    href={`${envConfigs.uploadHost}${contentByLanguage?.attachment}`}
                                                    key={`list-${index}`}
                                                >
                                                    {contentByLanguage?.page_name}
                                                </LinkToDownload>
                                            );
                                        }

                                        return (
                                            <LinkToStaticPage to={`/staticInfoPage/${item.slug}`} key={`list-${index}`}>
                                                {contentByLanguage?.page_name}
                                            </LinkToStaticPage>
                                        );
                                    })}
                                </Column>
                            );
                        })}

                        <Column>
                            <PaymentMethods>
                                <PaymentMethodsText>{t.payment_methods || 'Способы оплаты'}:</PaymentMethodsText>

                                <PaymentMethodsLogos>
                                    <PaymentMethodLogo src={kortiMilliLogo} />
                                </PaymentMethodsLogos>
                            </PaymentMethods>
                        </Column>
                    </MainBlock>

                    <SocialsBlock>
                        <HideOnPc>
                            {/* <LogoBlock>
                                <FlyLogoWhite />
                            </LogoBlock> */}
                        </HideOnPc>

                        <SocialsLinks>
                            <SocialLink target="_blank" href="https://www.facebook.com/tojikdipservice">
                                <FacebookIcon />
                            </SocialLink>

                            <SocialLink target="_blank" href="https://instagram.com/tds.tj?igshid=YmMyMTA2M2Y=">
                                <InstagramIcon />
                            </SocialLink>

                            {/* <SocialLink target="_blank" href="https://t.me/flytj">
                                <TelegramIcon style={{ color: '#5452dd' }} />
                            </SocialLink>

                            <SocialLink target="_blank" href="https://wa.me/992900559009">
                                <WhatsAppIcon />
                            </SocialLink> */}
                        </SocialsLinks>
                    </SocialsBlock>

                    <DontCopyText>{t.agreement_footer_text}</DontCopyText>
                </Block>
            </Container>
        </Component>
    );
};

export default Footer;
