import { IconButton } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

import { ReactComponent as FlyIcon } from '../../../assets/icons/newDesign/flyLogoColoredIcon.svg';
import popupImage from '../../../images/popup-image.jpg';

const fadeIn = keyframes`
    from {
        transform: translateY(100%);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
`;

const fadeOut = keyframes`
    from {
        transform: translateY(0);
        opacity: 1;
    }
    
    to {
        transform: translateY(100%); 
        opacity: 0;
    }
`;

export const Wrapper = styled.div`
    display: ${props => (props.isShow ? 'block' : 'none')};
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 250;
    background-color: rgba(0, 0, 0, 0.7);
`;

export const Backdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 251;
    animation-name: ${props => (props.isShow ? fadeIn : fadeOut)};
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 599px) {
        align-items: flex-end;
    }
`;

export const Modal = styled.div`
    display: flex;
    background-color: #fff;
    height: 600px;
    width: 700px;
    position: relative;
    border-radius: 8px;

    @media (max-width: 959px) {
        width: 100%;
        margin: 0 16px;
    }

    @media (max-width: 599px) {
        margin: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        height: auto;
    }
`;

export const CloseButton = styled(IconButton)`
    position: absolute !important;
    right: 12px;
    top: 10px;
`;

export const ImageBlock = styled.div`
    width: 40%;
    background-image: url(${popupImage});
    background-position: center right;
    background-size: cover;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;

    @media (max-width: 599px) {
        display: none;
    }
`;

export const TextBlock = styled.div`
    width: 60%;
    display: flex;
    flex-direction: column;
    padding: 0 16px;

    @media (max-width: 599px) {
        width: 100%;
    }
`;

export const LogoBlock = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 96px 0 32px;

    @media (max-width: 599px) {
        padding: 32px 0;
    }
`;

export const Logo = styled(FlyIcon)``;

export const EventTitle = styled.div`
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 12px;
    color: #404b5a;

    @media (max-width: 599px) {
        font-size: 20px;
    }
`;

export const EventText = styled.div`
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    color: #404b5a;

    @media (max-width: 599px) {
        font-size: 18px;
    }
`;

export const EventLink = styled(NavLink)`
    color: #5452dd;
    text-decoration: none;
`;

export const ButtonBlock = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 0;

    @media (max-width: 599px) {
        padding: 32px 0;
    }
`;

export const Button = styled(NavLink)`
    border-radius: 12px;
    background-color: #5452dd;
    border: none;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    padding: 12px 24px;
    text-decoration: none;
`;

export const Description = styled.div`
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    color: #404b5a;

    @media (max-width: 599px) {
        display: none;
    }
`;
