import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';

export const Wrapper = styled.div`
    width: 336px;
    height: 120px;
    opacity: 0.8;
    border-radius: 4px;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.12), 0 4px 4px 0 rgba(0, 0, 0, 0.08);
    background-color: #ffffff;
    display: inline-table;
    cursor: pointer;
    position: relative;

    @media (max-width: 959px) {
        width: 100%;
        height: auto;
        margin-bottom: 24px;
    }
`;

export const FareGrid = styled(Grid)`
    padding: 16px 20px 20px 20px;
`;

export const BrandNameGrid = styled(Grid)`
    width: 80%;
    display: inline-block;
    font-size: 20px;
    font-weight: 500;
    color: #212121;
`;

export const BrandCheckedIconGrid = styled(Grid)`
    position: absolute;
    right: 16px;
    top: 16px;
    color: #5452dd;
    text-align: right;
    cursor: pointer;
`;

export const BrandTotalPriceGrid = styled(Grid)`
    width: 100%;
    font-size: 16px;
    font-weight: 500;
`;

export const BrandFareInfoGrid = styled(Grid)`
    width: 100%;
`;

export const BrandTariffsRulesGrid = styled(Grid)`
    padding-top: 12px;
    font-size: 16px;
    font-weight: 500;
`;

export const BrandTariffsRulesLabel = styled.div`
    cursor: pointer;
    color: ${props => props.loading ? '#9da8ae' : '#5452dd'};
    display: flex;
    align-items: center;
`;
