import styled from 'styled-components';

export const Block = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Row = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;

    &:nth-last-of-type(1) {
        margin-bottom: 0;
    }
`;

export const TextBlock = styled.div`
    display: flex;
    flex-direction: column;
`;

export const TextTitle = styled.div`
    font-size: 0.9rem;
    font-weight: bold;
`;

export const TextDescription = styled.div`
    font-size: 0.6rem;
    color: #a0b0b9;
    width: 96px;
    line-height: 1.2;
`;

export const ButtonsBlock = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const Button = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: ${props => (props.disabled ? '#cdd4de' : '#FFC20E')};
    border-radius: 6px;
    width: 24px;
    height: 24px;
    cursor: pointer;

    &:focus {
        outline: none;
    }
`;

export const Count = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    margin: 0 12px;
`;

export const TextPlain = styled.div`
    font-size: 1rem;
    font-weight: bold;
    color: #5452dd;
    margin-bottom: 12px;
    text-align: center;
    width: 100%;
`;
