import styled from 'styled-components';

export const SubscriptionBlock = styled.div`
    display: flex;
    justify-content: center;
    padding: 16px 0;

    @media (max-width: 959px) {
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
    }
`;
export const Icon = styled.img`
    @media (max-width: 959px) {
        display: none;
    }
`;
export const EmailBlock = styled.div`
    @media (max-width: 959px) {
        margin-top: 0;
        margin-left: 0;
    }
`;
export const PlainText = styled.div``;
export const P = styled.p`
    font-size: 20px;
    font-weight: bold;
    color: #404b5a;
    text-transform: uppercase;

    @media (max-width: 959px) {
        font-size: 18px;
        text-align: center;
    }
`;
export const Inform = styled.div`
    width: 378px;
    font-size: 18px;
    color: #aabacc;

    @media (max-width: 959px) {
        color: #404b5a;
        font-size: 12px;
        width: 217px;
    }
`;
export const InputEmail = styled.div`
    margin-top: 20px;
    display: flex;
    align-items: center;

    @media (max-width: 959px) {
        display: flex;
        flex-direction: column;
        margin-top: 8px;
    }
`;
export const Input = styled.input`
    width: 380px;
    height: 56px;
    border-radius: 4px;
    border: 1px solid #5452dd;
    margin-right: 12px;
    color: #aabacc;
    font-size: 18px;
    padding-left: 8px;

    ::placeholder {
        color: #aabacc;
        font-size: 18px;
    }

    @media (max-width: 959px) {
        width: 100%;
        margin-right: 0;
    }
`;
export const Button = styled.button`
    height: 56px;
    width: 248px;
    border-radius: 12px;
    background-color: #5452dd;
    border: none;
    color: #fff;
    text-transform: uppercase;
    font-size: 25px;
    font-weight: bold;
    cursor: pointer;

    @media (max-width: 959px) {
        width: 100%;
        border-radius: 4px;
        margin-top: 8px;
    }
`;
export const IconForMobile = styled.img`
    display: none;

    @media (max-width: 959px) {
        display: block;
        width: 111px;
    }
`;

export const ResultBlock = styled.div`
    text-align: left;
    height: 22px;

    @media (max-width: 959px) {
        text-align: center;
    }
`;
