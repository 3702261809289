import styled from 'styled-components';

export const ContactsBlock = styled.div`
    position: relative;
    border-radius: 20px;
    width: 35px;
    height: 35px;
    border: 1px solid #7574cd;
    overflow: hidden;
    transition: all 0.3s ease-in;
    margin-right: 15px;

    &:hover {
        border: 1px solid #5452dd;
    }

    &.active {
        transition: all 0.3s ease-in;
        width: 190px;
    }

    @media (max-width: 959px) {
        display: none;
    }
`;

export const Parent = styled.div`
    overflow: hidden;
`;

export const Child = styled.div`
    position: absolute;
    right: 0;
    top: 4px;
    width: 175px;
    display: flex;
    align-items: center;
`;

export const PhoneNumber = styled.a`
    text-decoration: none;
    color: #404b5a;
    line-height: 1;
    font-weight: 600;
`;
