import styled from 'styled-components';

export const CityBlock = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    background-color: #fff;
    margin-bottom: 32px;
`;

export const CityName = styled.h2`
    color: #404b5a;
    margin-bottom: 16px;
    margin-top: 0;
`;

export const CityTable = styled.div`
    display: flex;
    flex-direction: column;
`;

export const CityTableRow = styled.div`
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
    position: relative;

    &.header {
        font-weight: bold;
        color: #404b5a;

        &:hover {
            background-color: transparent;
            color: #404b5a;
            cursor: default;
        }
    }

    &:hover {
        background-color: #5452dd;
        color: #fff;
        cursor: pointer;
    }

    &:nth-last-of-type(1) {
        border-bottom: none;
    }
`;

export const CityTableRowLoading = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    background-color: rgba(0, 133, 255, 0.8);
    color: #fff;
`;

export const CityTableColumn = styled.div`
    text-align: center;
    padding: 8px;
`;

export const CityTableColumnTo = styled(CityTableColumn)`
    width: 40%;
    text-align: left;

    @media (max-width: 959px) {
        width: 70%;
    }
`;

export const CityTableColumnWeekDays = styled(CityTableColumn)`
    width: 25%;

    &.uppercase {
        text-transform: uppercase;
    }

    @media (max-width: 959px) {
        width: 30%;
    }
`;

export const CityTableColumnAirline = styled(CityTableColumn)`
    width: 35%;

    @media (max-width: 959px) {
        display: none;
    }
`;

export const NoResultsBlock = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    font-size: 20px;
    font-weight: bold;
    color: #404b5a;
`;
