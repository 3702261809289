import styled from 'styled-components';
import { Settings } from '@material-ui/icons';

export const Wrapper = styled.div`
    margin-left: 12px;
`;

export const Button = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 42px;
    width: 42px;
    border: 1px solid #5452dd;
    border-radius: 50%;
    cursor: pointer;
    background-color: transparent;
`;

export const SettingsIcon = styled(Settings)`
    width: 36px;
    height: 36px;
    color: #5452dd;
`;

export const GroupTitle = styled.div`
    margin-bottom: 8px;
    font-weight: bold;
    color: #5452dd;
`;

export const Items = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: 32px;
`;

export const Item = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
    color: #000;
`;

export const Code = styled.div`
    color: #9eb3c7;
    margin-left: 8px;
    font-size: 14px;
    line-height: 1.5;
    font-weight: 400;
    transition: color 0.26s ease;
    user-select: none;
    text-transform: uppercase;
`;

export const Label = styled.div`
    color: #404b5a;
    margin-left: 8px;
    font-size: 14px;
    font-weight: 400;
    position: relative;
    width: 80%;
    text-align: left;

    &.active {
        color: #7574cd;
    }
`;

export const CheckboxBlock = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 4px;
`;

export const Checkbox = styled.div`
    /* background-color: #fff; */
    width: 15px;
    height: 15px;
    position: relative;
    border-radius: 50%;
    transition: border-color 0.26s ease;
    background-color: #e4e9f3;
    border: 1px solid #a0b0b9;

    &:after {
        content: '';
        width: 8px;
        height: 8px;
        position: absolute;
        top: 50%;
        left: 50%;
        background: transparent;
        border-radius: 50%;
        transform: translate(-50%, -50%);
        transition: background-color 0.26s ease;
    }

    &.active {
        border: 3px solid #7574cd;
    }
`;
