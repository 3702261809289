import React, { useEffect, useState } from 'react';
import ClientsRewiev from './parts/clientsRewiev';
import LastNews from './parts/lastNews';
import PopularDestinationTable from './parts/popularDestination';
import SubscriptionToNewsletter from './parts/subscriptionToNewsletter';
import ToursAndDiscounts from './parts/toursAndDiscounts';
import OurPartners from './parts/ourPartners';
import API from '../../../lib/api';

const Main = () => {
    const [data, setData] = useState(null);
    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const result = await API.getMainData();
        setData(result);
    };

    if (!data) return null;

    return (
        <>
            <ToursAndDiscounts tours={data?.tours} />
            <PopularDestinationTable popularDestinations={data?.popularDestinations} />
            <LastNews news={data?.news} />
            <ClientsRewiev reviews={data?.reviews} />
            <SubscriptionToNewsletter />
            <OurPartners partners={data?.partners} />
        </>
    );
};

export default Main;
