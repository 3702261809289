import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const Component = styled.div`
    background-color: #1c222a;
`;

export const HideOnMobile = styled.div`
    @media (max-width: 959px) {
        display: none;
    }
`;

export const HideOnPc = styled.div`
    display: none;

    @media (max-width: 959px) {
        display: flex;
    }
`;

export const Block = styled.div`
    display: flex;
    flex-direction: column;
`;

export const MainBlock = styled.div`
    order: 1;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #d2d2d2;
    padding: 48px 0;

    @media (max-width: 959px) {
        order: 2;
        flex-direction: column;
        justify-content: flex-start;
    }
`;

export const Column = styled.div`
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (max-width: 959px) {
        width: 100%;
        margin-bottom: 16px;
    }
`;

export const LogoBlock = styled.div`
    margin-bottom: 16px;
`;

export const LogoImage = styled.img`
    width: 64px;
`;

export const ContactUsText = styled.div`
    font-size: 18px;
    color: #FFC20E;
    margin-bottom: 8px;
`;

export const ContactsEmail = styled.div`
    color: #fff;
    margin-bottom: 8px;
`;

export const ContactPhone = styled.div`
    font-size: 18px;
    color: #FFC20E;
`;

export const LinkToDownload = styled.a`
    color: #fff;
    text-decoration: none;
    font-size: 18px;
    margin-bottom: 8px;

    &::nth-last-of-type(1) {
        margin-bottom: 0;
    }

    &:hover {
        color: #FFC20E;
    }
`;

export const LinkToStaticPage = styled(NavLink)`
    color: #fff;
    text-decoration: none;
    font-size: 18px;
    margin-bottom: 8px;

    &::nth-last-of-type(1) {
        margin-bottom: 0;
    }

    &:hover {
        color: #FFC20E;
    }
`;

export const SocialsBlock = styled.div`
    order: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 24px 0;

    @media (max-width: 959px) {
        order: 1;
        border-bottom: 1px solid #d2d2d2;
        padding: 32px 0;
    }
`;

export const SocialsLinks = styled.div`
    display: flex;
    align-items: center;

    @media (max-width: 959px) {
        margin-top: 16px;
    }
`;

export const SocialLink = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: #e4e9f3;
    border-radius: 50%;
    color: initial;
    margin-right: 16px;

    &:nth-last-of-type(1) {
        margin-right: 0;
    }
`;

export const DontCopyText = styled.div`
    order: 3;
    text-align: center;
    font-size: 12px;
    color: #e4e9f3;
    margin-bottom: 32px;

    @media (max-width: 959px) {
        margin-top: 32px;
    }
`;

export const PaymentMethods = styled.div`
    display: flex;
    flex-direction: column;
`;

export const PaymentMethodsText = styled.div`
    font-size: 18px;
    color: #FFC20E;
    margin-bottom: 8px;
`;

export const PaymentMethodsLogos = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const PaymentMethodLogo = styled.img`
    max-width: 60px;
`;
