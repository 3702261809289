import { Grid } from '@material-ui/core';
import styled from 'styled-components';

export const Wrapper = styled.div`
    overflow: hidden;
    display: none;
    margin: 12px 16px;
    background-color: #fff;
    padding: ${props => (props.hasSpecialText ? '42px 16px 16px' : '16px')};
    border-radius: 12px;
    box-shadow: 0 0 5px 1px rgba(0, 59, 110, 0.1);
    position: relative;

    @media screen and (max-width: 959px) {
        display: block;
    }

    @media (max-width: 599px) {
        margin: 12px 0;
    }
`;

export const SpecialText = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    top: 8px;
    right: 16px;
    height: 24px;
    border-radius: 24px;
    padding: 0px 10px;
    text-align: center;
    background-color: #5ba554;
    color: #fff;
    font-size: 14px;
`;

export const HeadBlockGrid = styled(Grid)`
    padding-bottom: 12px;
`;

export const GridBlock = styled(Grid)`
    position: relative;
    justify-content: space-between;
`;

export const PriceBlock = styled.div`
    font-size: 24px;
    font-weight: bold;
    display: flex;
    color: #404b5a;

    & > span {
        color: #5452dd;
        margin-right: 8px;
    }
`;

export const TopOval = styled.div`
    position: absolute;
    background-color: #5452dd;
    width: 67%;
    height: 44px;
    border-radius: 50%;
    top: -52px;
    left: 50%;
    transform: translateX(-50%);
`;

export const SupplierIcon = styled.img`
    display: block;
`;

const FlightsBadge = styled.div`
    color: #fff;
    width: 100px;
    border-radius: 4px;
    text-align: center;
    margin-top: 4px;
    font-size: 14px;
`;

export const Lowcost = styled(FlightsBadge)`
    background-color: #4caf50;
`;

export const Charter = styled(FlightsBadge)`
    background-color: #67d457;
`;

export const RouteBlockGrid = styled(Grid)`
    padding-top: 12px;
    font-weight: 500;
    position: relative;
`;

export const ItemInfoTitle = styled.div`
    font-size: 14px;
    color: #a0b0b9;
    text-transform: capitalize;
    font-weight: bold;
    text-align: ${props => (props.right ? 'right' : 'left')};
`;

export const ItemInfoValue = styled.div`
    font-size: ${props => props.fontSize || '14px'};
    font-weight: bold;
    text-align: ${props => (props.right ? 'right' : 'left')};
    color: ${props => props.color || 'inherit'};
`;
