import styled from 'styled-components';
import ExpandMore from '@material-ui/icons/ExpandMore';

export const Accordion = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #fff;
    box-shadow: 0 4px 10px 1px rgba(0, 59, 110, 0.1);
    border-radius: 4px;
`;

export const AccordionHeader = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    padding: 16px;
    cursor: pointer;
`;

export const AccordionHeaderTitle = styled.div`
    font-size: 18px;
    font-weight: bold;
    color: #404b5a;
    flex-grow: 1;
    text-align: center;
`;

export const AccordionHeaderButton = styled.button`
    padding: 0;
    margin: 0;
    background-color: transparent;
    border: none;

    &:hover {
        cursor: pointer;
    }
`;

export const AccordionHeaderButtonIcon = styled(ExpandMore)`
    display: block !important;
    font-size: 30px !important;
    color: #404b5a !important;

    &.active {
        transform: rotate(180deg);
    }
`;

export const AccordionBody = styled.div`
    height: ${props => (props.active ? 'auto' : 0)};
    overflow: hidden;
    padding: 0 16px;
`;

export const AccordionFooter = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: ${props => (props.active ? 'auto' : 0)};
    overflow: hidden;
    padding: ${props => (props.active ? '0 16px 16px' : 0)};
`;

export const AccordionFooterButton = styled.button`
    font-size: 14px;
    font-weight: bold;
    background-color: transparent;
    border: none;
    border-radius: 4px;
    padding: 8px;
    text-transform: uppercase;
    color: #5452dd;

    &:hover {
        background-color: #e0eef9;
        cursor: pointer;
    }
`;
