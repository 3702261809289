export default [
    {
        code: 'DYU',
        countryCode: 'TJ',
        countryName: {
            ru: 'Таджикистан',
            en: 'Tajikistan',
            tj: 'Тоҷикистон',
        },
        value: {
            ru: 'Душанбе',
            en: 'Dushanbe',
            tj: 'Душанбе',
        },
        isMain: 1,
    },
    {
        code: 'LBD',
        countryCode: 'TJ',
        countryName: {
            ru: 'Таджикистан',
            en: 'Tajikistan',
            tj: 'Тоҷикистон',
        },
        value: {
            ru: 'Худжанд',
            en: 'Khudzhand',
            tj: 'Хуҷанд',
        },
        isMain: 1,
    },
    {
        code: 'KQT',
        countryCode: 'TJ',
        countryName: {
            ru: 'Таджикистан',
            en: 'Tajikistan',
            tj: 'Тоҷикистон',
        },
        value: {
            ru: 'Бохтар',
            en: 'Bokhtar',
            tj: 'Бохтар',
        },
        isMain: 1,
    },
    {
        code: 'TJU',
        countryCode: 'TJ',
        countryName: {
            ru: 'Таджикистан',
            en: 'Tajikistan',
            tj: 'Тоҷикистон',
        },
        value: {
            ru: 'Куляб',
            en: 'Kulyab',
            tj: 'Кӯлоб',
        },
        isMain: 1,
    },
    {
        code: 'MOW',
        countryCode: 'RU',
        countryName: {
            ru: 'Россия',
            en: 'Russia',
            tj: 'Русия',
        },
        value: {
            ru: 'Москва',
            en: 'Moscow',
            tj: 'Маскав',
        },
        isMain: 1,
    },
    {
        code: 'SVO',
        countryCode: 'RU',
        countryName: {
            ru: 'Россия',
            en: 'Russia',
            tj: 'Русия',
        },
        value: {
            ru: 'Шереметьево',
            en: 'Sheremetyevo International',
            tj: 'Sheremetyevo International',
        },
        isMain: 0,
    },
    {
        code: 'VKO',
        countryCode: 'RU',
        countryName: {
            ru: 'Россия',
            en: 'Russia',
            tj: 'Русия',
        },
        value: {
            ru: 'Внуково',
            en: 'Vnukovo International',
            tj: 'Vnukovo International',
        },
        isMain: 0,
    },
    {
        code: 'DME',
        countryCode: 'RU',
        countryName: {
            ru: 'Россия',
            en: 'Russia',
            tj: 'Русия',
        },
        value: {
            ru: 'Домодедово',
            en: 'Domodedovo',
            tj: 'Domodedovo',
        },
        isMain: 0,
    },
    {
        code: 'ALA',
        countryCode: 'KZ',
        countryName: {
            ru: 'Казахстан',
            en: 'Kazakhstan',
            tj: 'Қазоқистон',
        },
        value: {
            ru: 'Алматы',
            en: 'Almaty',
            tj: 'Almaty',
        },
        isMain: 1,
    },
    {
        code: 'IST',
        countryCode: 'TR',
        countryName: {
            ru: 'Турция',
            en: 'Turkey',
            tj: 'Туркия',
        },
        value: {
            ru: 'Стамбул',
            en: 'Istanbul',
            tj: 'Istanbul',
        },
        isMain: 1,
    },
];
