import styled from 'styled-components';

export const Component = styled.div`
    margin-right: 16px;
    position: relative;
`;

export const Button = styled.button`
    display: flex;
    align-items: ${props => (props.notAlign ? null : 'center')};
    justify-content: ${props => (props.notJustify ? null : 'center')};
    background-color: transparent;
    color: ${props => (props.langColor ? '#fff' : '#5452dd')};
    border: ${props => (props.notBorder ? 'none' : '1px solid #7574cd')};
    min-width: 50px;
    padding: ${props => (props.addPadding ? '' : '8px 12px')};
    border-radius: ${props => (props.notRadius ? '' : '100px')};
    font-weight: bold;
    font-size: 16px;
    line-height: 1;
    height: 35px;

    &:hover {
        cursor: pointer;
        border: ${props => (props.noHover ? null : '1px solid #5452dd')};
    }

    & > svg {
        margin-left: 4px;
    }
`;

export const Dropdown = styled.div`
    display: ${props => (props.active ? 'block' : 'none')};
    position: absolute;
    top: calc(100% + 10px);
    right: 0;
    background-color: #fff;
    padding: 8px;
    border-radius: 12px;
    width: 200px;
    box-shadow: 0 0 5px 1px rgba(0, 59, 110, 0.1);
`;

export const Items = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
`;

export const Item = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 6px 0;
    color: #000;
    padding: 10px;

    &:hover {
        cursor: pointer;
        background-color: #ecf2f8;
        border-radius: 8px;
    }
`;

export const Text = styled.div`
    color: #9eb3c7;
    margin-left: 8px;
    font-size: 14px;
    line-height: 1.5;
    font-weight: 400;
    transition: color 0.26s ease;
    user-select: none;
    text-transform: uppercase;
`;

export const TextLanguage = styled.div`
    color: #404b5a;
    margin-left: 8px;
    font-size: 14px;
    font-weight: 400;
    position: relative;
    float: left;
    width: 80%;
`;

export const CheckboxBlock = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Checkbox = styled.div`
    background-color: #fff;
    width: 15px;
    height: 15px;
    position: relative;
    border: 3px solid #e4e9f3;
    border-radius: 50%;
    transition: border-color 0.26s ease;
    background-color: #e4e9f3;

    &:after {
        content: '';
        width: 8px;
        height: 8px;
        position: absolute;
        top: 50%;
        left: 50%;
        background: transparent;
        border-radius: 50%;
        transform: translate(-50%, -50%);
        transition: background-color 0.26s ease;
    }

    &.active {
        border-color: #7574cd;
    }
`;

export const LanguageBlock = styled.div`
    position: relative;
`;

export const Label = styled.label`
    color: #FFC20E;
    margin-left: 4px;
`;
