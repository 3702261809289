import styled from 'styled-components';
import { Menu } from '@material-ui/icons';

import Link from '../../../../../../../ui/components/Link/Link';

export const Wrapper = styled.div`
    margin-left: 12px;
`;

export const Button = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 42px;
    width: 42px;
    border: 1px solid #5452dd;
    border-radius: 50%;
    cursor: pointer;
    background-color: transparent;
`;

export const MenuIcon = styled(Menu)`
    width: 36px;
    height: 36px;
    color: #5452dd;
`;

export const StyledLink = styled(Link)`
    display: block;
    color: #5452dd;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.6;
    margin: ${props => (props.marginTop ? '16px' : 0)} 0 16px;
`;

export const LinkForAgents = styled.a`
    display: block;
    color: #5452dd;
    font-size: 18px;
    font-weight: bold;
    font-weight: 600;
    line-height: 1.6;
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: #5452dd;
    text-decoration: none;
    margin: 0 0 16px;
`;

export const ClientName = styled.div`
    font-size: 20px;
    color: #000;
    font-weight: bold;
    color: #7574cd;
`;

export const ClientLogin = styled.div`
    color: #aeaeae;
    font-size: 14px;
    margin-bottom: 8px;
`;

export const ClientBalanceBlock = styled.div`
    display: flex;
    padding: 16px 0;
`;

export const ClientBalanceTitle = styled.div`
    color: #aeaeae;
    margin-right: 8px;
`;

export const ClientBalanceValue = styled.div`
    color: #404b5a;
    font-weight: bold;

    & > span {
        margin-left: 4px;
        color: #5452dd;
    }
`;

export const ExitButton = styled.div`
    color: #000;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.6;
`;
