import styled from 'styled-components';

export const Block = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 16px;
`;

export const Row = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0;
    width: 100%;

    &:hover {
        cursor: pointer;
        color: #7574cd;
    }
`;

export const Checkbox = styled.div`
    background-color: #e4e9f3;
    width: 15px;
    height: 15px;
    position: relative;
    border: 1px solid #a0b0b9;
    border-radius: 50%;
    transition: border-color 0.26s ease;

    &:after {
        content: '';
        width: 8px;
        height: 8px;
        position: absolute;
        top: 50%;
        left: 50%;
        background: transparent;
        border-radius: 50%;
        transform: translate(-50%, -50%);
        transition: background-color 0.26s ease;
    }

    &.active {
        border: 3px solid #7574cd;
    }
`;

export const Text = styled.div`
    color: inherit;
    font-weight: bold;
    line-height: 1.5;
    transition: color 0.26s ease;
    user-select: none;

    &.active {
        color: #7574cd;
    }
`;
