import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;

    @media (max-width: 959px) {
        max-height: 100%;
    }
`;

export const WeekNamesBlock = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f6f9ff;

    @media (max-width: 959px) {
        position: sticky;
        top: 0;
        z-index: 10;
    }
`;

export const WeekNames = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 4px;
`;

export const WeekName = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    line-height: 1;
    width: 44px;
    height: 36px;
    color: #9ea9b7;
`;

export const Months = styled.div`
    display: flex;
    flex-direction: column;
    max-height: 320px;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }

    @media (max-width: 959px) {
        max-height: none;
    }
`;

export const MonthBlock = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    
    &:nth-of-type(1) {
        margin-top: 20px;    
    }

    &:nth-last-of-type(1) {
        margin-bottom: 10px;
    }
`;

export const Month = styled.div`
    display: flex;
    flex-direction: column;
`;

export const MonthTitle = styled.div`
    font-weight: bold;
    text-transform: capitalize;
    margin-bottom: 8px;
`;

export const MonthWeeks = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

export const WeekBlock = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 4px;
`;

export const WeekdayButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 36px;
    font-size: 0.8rem;
    font-weight: bold;
    border-radius: 3px;

    background-color: ${props => (props.active ? '#7574cd' : props.between ? '#eaf5ff' : 'transparent')};
    color: ${props => (props.active ? '#fff' : '#000')};

    opacity: ${props => (props.disabled ? 0.3 : 1)};

    @media (max-width: 404px) {
        width: 40px;
        height: 36px;
        margin: 0;
    }

    &:hover {
        background-color: ${props => (props.active ? '#7574cd' : props.disabled ? 'transparent' : '#eaf5ff')};
        border: ${props => (props.disabled ? 'none' : '1px solid #7574cd')};
        cursor: ${props => (props.disabled ? 'default' : 'pointer')};
    }
`;
