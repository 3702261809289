import React, { useContext } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Field, reduxForm } from 'redux-form';
import { Grid, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useStyles } from './styles';
import InputUI from '../../../components/ui/components/InputUI/InputUI';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import {
    ALL_ORDERS_CODE,
    BOOK_DONE_CODE,
    BOOKING_DONE_CODE,
    INITIAL_CODE,
    TICKET_DONE_CODE,
    PAY_DONE_CODE,
    TICKETING_DONE_CODE,
    PAY_SUCCESS_CODE,
    CANCEL_DONE_CODE,
    REFUND_DONE_CODE,
    REFUND_CODE,
    APPLICATION_REFUND_CODE,
    PARTIAL_REFUND_DONE_CODE,
    PARTIAL_REFUND_CODE,
    VOID_DONE_CODE,
    VOID_CODE,
    ORDER_FAIL_CODE,
    ORDER_CANCEL_ERROR_CODE,
    WAIT_REFUND_CODE,
    ERROR_ORDER_CODE,
    BOOKING_CANCEL_FROM_CLIENT_CODE,
    BOOKING_CANCEL_FROM_ADMIN_CODE,
    BOOKING_CANCEL,
} from '../../../containers/order/constants';
import DatePickerForm from '../../../components/ui/components/DatePickerForm/DatePickerForm';
import { validDateWithoutEmpty } from '../../../lib/validate';
import SelectFormUI from '../../../components/ui/components/SelectFormUI/SelectFormUI';
import useLocalization from '../../../contexts/localization/hook';

function SearchFieldsForm(props) {
    const classes = useStyles();
    const { t } = useLocalization();
    const { handleSubmit, reset, setFilters } = props;

    const handelOnSubmit = data => {
        const { searchString, orderStatus, dataFrom, dataTo } = data;

        let filters = {};

        if (!_.isEmpty(searchString)) {
            mergeSearchFilterParams(filters, 'searchAll', searchString);
        }

        if (!_.isEmpty(dataFrom)) {
            mergeSearchFilterParams(filters, 'timestamp_start', formatDateForRequest(dataFrom));
        }

        if (!_.isEmpty(dataTo)) {
            mergeSearchFilterParams(filters, 'timestamp_end', formatDateForRequest(dataTo));
        }

        if (orderStatus !== ALL_ORDERS_CODE) {
            const [paramType, statuses] = getSearchStatuses(orderStatus);

            mergeSearchFilterParams(filters, [paramType], statuses);
        }

        setFilters(filters);
    };

    const handleOnBlur = event => {
        event.preventDefault();
    };

    const mergeSearchFilterParams = (filters, symbol, value) => {
        _.merge(filters, {
            [symbol]: value,
        });
    };

    const getSearchStatuses = status => {
        let paramType = 'booking_status';

        if (status === BOOK_DONE_CODE) {
            return [paramType, [BOOKING_DONE_CODE, INITIAL_CODE]];
        } else if (status === TICKET_DONE_CODE) {
            return [paramType, [TICKETING_DONE_CODE]];
        } else if (status === PAY_DONE_CODE) {
            return [paramType, [PAY_SUCCESS_CODE]];
        } else if (status === CANCEL_DONE_CODE) {
            return [paramType, [BOOKING_CANCEL_FROM_CLIENT_CODE, BOOKING_CANCEL_FROM_ADMIN_CODE, BOOKING_CANCEL]];
        } else if (status === REFUND_DONE_CODE) {
            return [paramType, [REFUND_CODE]];
        } else if (status === PARTIAL_REFUND_DONE_CODE) {
            return [paramType, [PARTIAL_REFUND_CODE]];
        } else if (status === WAIT_REFUND_CODE) {
            return [paramType, [APPLICATION_REFUND_CODE]];
        } else if (status === VOID_DONE_CODE) {
            return [paramType, [VOID_CODE]];
        } else {
            return ['order_status', [ORDER_FAIL_CODE, ORDER_CANCEL_ERROR_CODE, INITIAL_CODE]];
        }
    };

    const handelReset = () => {
        reset();
        setFilters({});
    };

    const formatDateForRequest = date => {
        const dateArray = date.split('.');

        return dateArray[2] + '-' + dateArray[1] + '-' + dateArray[0];
    };

    return (
        <div className={classes.searchFieldBlock}>
            <form onSubmit={handleSubmit(data => handelOnSubmit(data))}>
                <Grid container className={classes.searchFields}>
                    <Grid item className={classes.searchSingleItem}>
                        <div className={classes.itemBlock}>
                            <Field component={StringField} name="searchString" onBlur={handleOnBlur} />
                        </div>
                    </Grid>
                    <Grid item className={classes.searchSingleItem}>
                        <div className={classes.orderStatusSearchBlock}>
                            <Field component={OrderStatusField} name="orderStatus" onBlur={handleOnBlur} />
                        </div>
                    </Grid>
                    <Grid item className={classes.test1}>
                        <Grid container spacing={1}>
                            <Grid item className={classes.searchDoubleItem}>
                                <div className={classes.dateFromBlock}>
                                    <Field
                                        component={DateField}
                                        onBlur={handleOnBlur}
                                        width="100%"
                                        label={t.date_create_from}
                                        name="dataFrom"
                                        validate={[validDateWithoutEmpty]}
                                    />
                                </div>
                            </Grid>
                            <Grid item className={classes.searchDoubleItem}>
                                <div className={classes.dateToBlock}>
                                    <Field
                                        component={DateField}
                                        label={t.date_create_to}
                                        onBlur={handleOnBlur}
                                        name="dataTo"
                                        validate={[validDateWithoutEmpty]}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.test1}>
                        <Grid container spacing={1}>
                            <Grid item className={classes.searchDoubleItem}>
                                <ClearButton reset={handelReset} />
                            </Grid>
                            <Grid item className={classes.searchDoubleItem}>
                                <div>
                                    <SearchButton />
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </div>
    );
}

const ClearButton = props => {
    const { t } = useLocalization();
    const { reset } = props;

    const StyledButton = withStyles({
        root: {
            width: '100%',
            height: '46px',
            borderRadius: '4px',
            fontSize: '18px',
            fontWeight: 500,
            backgroundColor: '#fafbfc',
        },
        label: {
            textTransform: 'none',
        },
    })(Button);

    return (
        <StyledButton onClick={reset} disableRipple>
            {t.clear}
        </StyledButton>
    );
};

const SearchButton = () => {
    const { t } = useLocalization();

    const StyledButton = withStyles({
        root: {
            width: '100%',
            height: '46px',
            borderRadius: '4px',
            backgroundColor: '#5452dd',
            fontSize: '18px',
            fontWeight: 500,
            color: '#ffffff',

            '&:hover': {
                backgroundColor: '#0d6dc5',
            },
            '&:active': {
                backgroundColor: '#137dde',
            },
        },
        label: {
            textTransform: 'none',
        },
    })(Button);

    return (
        <StyledButton disableRipple type="submit">
            {t.find}
        </StyledButton>
    );
};

const DateField = props => {
    return <DatePickerForm {...props} />;
};

const OrderStatusField = props => {
    const { t } = useLocalization();
    const { input } = props;

    const items = [
        { value: ALL_ORDERS_CODE, label: t.all_orders_label },
        { value: BOOK_DONE_CODE, label: t.book_done_label },
        { value: PAY_DONE_CODE, label: t.pay_done_label },
        { value: TICKET_DONE_CODE, label: t.ticket_done_label },
        { value: CANCEL_DONE_CODE, label: t.cancel_done_label },
        { value: WAIT_REFUND_CODE, label: t.wait_refund_label },
        { value: REFUND_DONE_CODE, label: t.refund_done_label },
        { value: PARTIAL_REFUND_DONE_CODE, label: t.partial_refund_done_label },
        { value: VOID_DONE_CODE, label: t.void_done_label },
        { value: ERROR_ORDER_CODE, label: t.error_order_label },
    ];

    return (
        <SelectFormUI input={{ ...input, fullWidth: true }} items={items} width="100%" label={t.order_status_label} />
    );
};

const StringField = props => {
    const classes = useStyles();
    const { t } = useLocalization();

    return (
        <InputUI
            {...props}
            label={t.search_title}
            className={classes.searchFieldInput}
            style={{
                width: '100%',
                height: '56px',
            }}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon fontSize="small" className={classes.searchIcon} />
                    </InputAdornment>
                ),
            }}
        />
    );
};

SearchFieldsForm = reduxForm({
    form: 'searchOrders',
})(SearchFieldsForm);

SearchFieldsForm = connect(state => ({
    initialValues: {
        orderStatus: ALL_ORDERS_CODE,
    },
}))(SearchFieldsForm);

export default SearchFieldsForm;
