import styled from 'styled-components';

export const Wrapper = styled.div`
    margin-bottom: 48px;

    @media (max-width: 1279px) {
        display: none;
    }
`;

export const Tabs = styled.div`
    display: flex;
    flex-direction: column;
`;

export const TabsHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const TabsButtons = styled.div`
    display: flex;
`;

export const TabsButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: transparent;

    border-radius: 28px;
    border: 1px solid ${props => (props.active ? '#7574cd' : 'transparent')};
    padding: 12px 32px;
    color: ${props => (props.active ? '#7574cd' : '#404b5a')};
    margin: 0 32px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
`;

export const TabsBody = styled.div`
    padding-bottom: ${props => props.paddingBottom ? '24px' : 0};
`;

export const TabsContent = styled.div`
    display: none;
    padding: 24px 0 0;

    &.active {
        display: block;
    }
`;

export const Suppliers = styled.div`
    display: flex;
`;

export const SupplierItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const SupplierHeader = styled.div``;

export const SupplierLogo = styled.img`
    display: block;
    max-width: 80px;
`;

export const SupplierPrices = styled.div`
    padding-top: 12px;
`;

export const SupplierPrice = styled.div`
    margin-bottom: 4px;
    text-align: center;
`;

export const SupplierPriceButton = styled.button`
    background-color: transparent;
    border: none;
    font-weight: bold;
    color: #404b5a;
    font-size: 14px;
    padding: 8px 12px;
    border-radius: 4px;

    & > span {
        color: #5452dd;
    }

    &:hover {
        background-color: #5452dd;
        color: #fff;
        cursor: pointer;

        & > span {
            color: #fff;
        }
    }

    &.active {
        background-color: #5452dd;
        color: #fff;

        & > span {
            color: #fff;
        }
    }
`;

export const ActiveIndicator = styled.div`
    position: absolute;
    right: 20px;
    top: 6px;
    width: 10px;
    height: 10px;
    background-color: red;
    border-radius: 50%;
`;
