import React from 'react';
import { useHistory } from 'react-router-dom';
import defaultLogo from '../../../../../assets/icons/newDesign/flyLogoColoredIcon.svg';
import dipLogo from '../../../../../images/mainLogo/dip.webp';
import iataLogo from '../../../../../images/mainLogo/iata-logo.png';
import { Wrapper, LogoImage, IataLogoImage } from './components';

const Logo = () => {
    const history = useHistory();
    const handleOnClick = () => {
        history.push('/');
    };
    return (
        <Wrapper>
            <LogoImage alt="dip logo" src={dipLogo} onClick={() => handleOnClick()} />
            {/* <IataLogoImage alt="Iata logo icon" src={iataLogo} /> */}
        </Wrapper>
    );
};

export default Logo;
