import styled from 'styled-components';
import iconsOClose from '../../images/krest.png';
import handLuggage from '../../images/handluggerge.png';
import Baggage from '../../images/icons-baggage.png';
import ReturnImg from '../../images/icons-refound.png';
import ExchangeImg from '../../images/icons-change.png';
import { StyledButton } from '../common/index';

export const MainResultContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    margin-top: 32px;
    background-color: #f6f9ff;

    @media (max-width: 959px) {
        flex-direction: column;
    }
`;

export const MainResultFiltersContainer = styled.div`
    max-width: 25%;
    flex-basis: 25%;
    order: 1;

    @media (max-width: 1279px) {
        max-width: 32%;
        flex-basis: 32%;
    }

    @media (max-width: 959px) {
        order: unset;
        max-width: 100%;
        flex-basis: 100%;
        position: sticky;
        bottom: 8px;
        padding: 32px 0 40px;
    }
`;

export const MainResultRecommendationsContainer = styled.div`
    max-width: 73%;
    flex-basis: 73%;
    order: 2;

    @media (max-width: 1279px) {
        max-width: 66%;
        flex-basis: 66%;
    }

    @media (max-width: 959px) {
        max-width: 100%;
        flex-basis: 100%;
        order: unset;
    }
`;

export const FilterContainer = styled.div`
    width: 100%;
    float: left;
    margin-bottom: 32px;
`;

export const RecommendationBlock = styled.div`
    background-color: #fff;
    padding: 8px 16px;
    margin-bottom: 16px;
    border-radius: 22px;
    box-shadow: 0 4px 10px 1px rgba(0, 59, 110, 0.1);

    @media screen and (max-width: 960px) {
        display: none;
    }
`;

export const RouteTitleContainer = styled.div`
    display: flex;
    align-items: center;
    line-height: 1;
    padding: 7px 0;
    background-size: 24px;
    background-image: url(${props => props.imgUrl});
    background-repeat: no-repeat;
    background-position: left center;
`;

export const RouteTitle = styled.div`
    color: ${props => props.themeColor};
    margin-left: 32px;
    font-size: 18px;
    font-weight: 500;
`;

export const RouteMainInfo = styled.div`
    box-sizing: border-box;
    height: 150px;
    padding: 10px 10px 10px 0;
    position: relative;

    @media screen and (max-width: 1279px) {
        height: 260px;
    }

    & > div {
        height: 100%;
    }
`;

export const PentagonShape = styled.div`
    width: 38px;
    background: ${props => props.themeColor};
    position: absolute;
    left: -39px;
    top: 0;
    border-radius: 50%;
`;

export const InlineBlock = styled.div`
    display: inline-block;
`;

export const ButtonContainer = styled.div`
    margin-top: 16px;
    margin-bottom: 8px;
`;

export const ChoosedButton = styled(StyledButton)`
    width: 126px;
    height: 100%;
    border-radius: 8px;
    border: none;
    background-color: #5452dd;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: bold;

    &:hover {
        background-color: #5452dd;
    }
`;

export const ChoosedButtonBlock = styled(InlineBlock)`
    width: 126px;
    height: 48px;
    margin-left: 24px;
`;

export const AmountBlock = styled.div`
    display: flex;
    align-items: center;
    color: #404b5a;
    font-size: 34px;
    font-weight: bold;

    @media screen and (max-width: 600px) {
        font-size: 24px;
    }

    & > span {
        color: #5452dd;
        margin-right: 8px;
    }
`;

export const AmountSubmitBlock = styled.div`
    display: flex;
    justify-content: flex-end;

    @media screen and (max-width: 1279px) {
        flex-wrap: wrap;
    }

    @media screen and (max-width: 600px) {
        font-size: 24px;
    }
`;

export const PriceInfoWithTooltipBlock = styled.div`
    display: flex;
    align-items: center;
    margin-left: 10px;
`;

export const PriceInfoTooltipBlock = styled.div`
    padding: 0;
`;

export const PriceInfoTooltipRow = styled.div`
    font-size: 13px;
    padding: 2px 0;
`;

export const SupplierBlock = styled.div`
    color: #9da8ae;
    font-size: 14px;
`;

export const TransfersArrowImg = styled.div`
    height: 10px;
    background-image: url(${props => props.imgUrl});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;
`;

export const TimeRouteBlock = styled.div`
    color: #212121;
    font-size: 24px;
    font-weight: 500;
`;

export const RouteBlock = styled.div`
    font-size: 14px;
    font-weight: 500;
    color: #9da8ae;
`;

export const StringSpan = styled.span`
    margin-left: 3px;
`;

export const InlineTable = styled.div`
    display: inline-table;
`;

export const AirlineLogoBlock = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
`;

export const AirlineLogo = styled.div`
    height: 30px;
    width: 100%;
    background-image: url(${props => props.imgUrl});
    background-repeat: no-repeat;
    background-position: center center;
`;

export const DepartureInfo = styled.div`
    text-align: right;
    position: relative;
`;

export const DurationBlock = styled.div`
    text-align: center;
    position: relative;
    font-size: 14px;
    font-weight: 500;
    color: #9da8ae;
`;

export const ArrivalInfo = styled.div`
    text-align: left;
    position: relative;
`;

export const ShortTariffInfo = styled.div`
    text-align: right;
    position: relative;
`;

export const ClassBlock = styled.div`
    text-align: right;
    font-size: 16px;
    font-weight: 500;
    color: #212121;
`;

export const AdditionalInformationShort = styled.div`
    text-align: right;
    font-size: 14px;
    font-weight: 500;
    color: #9da8ae;
    margin-top: 4px;
`;

export const DefaultIconOption = styled(InlineBlock)`
    height: 13px;
    width: 13px;
    background-size: 13px;
    background-repeat: no-repeat;
    background-position: center center;
    margin-right: 9px;
`;

export const HandLuggageIcon = styled(DefaultIconOption)`
    background-image: url(${handLuggage});
`;

export const BaggageIcon = styled(DefaultIconOption)`
    background-image: url(${Baggage});
`;

export const ReturnIcon = styled(DefaultIconOption)`
    background-image: url(${ReturnImg});
`;

export const ExchangeIcon = styled(DefaultIconOption)`
    background-image: url(${ExchangeImg});
`;

export const freeSeatsLabelBlock = styled(InlineBlock)`
    vertical-align: 1px;
    padding-left: 12px;
`;

export const NotFoundRecommContainer = styled.div`
    margin: auto;
    max-width: 500px;
    padding: 50px 15px;
    text-align: center;
`;

export const NotFoundRecommMargin = styled.div`
    position: relative;
`;

export const NotFoundRecommFirstBlock = styled.div`
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 15px;
`;

export const NotFoundRecommSecondBlock = styled.div`
    font-size: 18px;
`;

export const ChangeTariffContainer = styled(ShortTariffInfo)`
    cursor: pointer;
    width: 100%;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

export const TariffChangeBlock = styled.div`
    margin: 0 0 16px;
    font-size: 14px;
    font-weight: 500;
    color: #5452dd;
    text-align: right;
    cursor: pointer;

    &:nth-last-of-type(1) {
        margin-bottom: 0;
    }
`;

export const RightTariffChangeBlock = styled(TariffChangeBlock)`
    border-left: solid 1px #cfd8dc;
    padding-left: 10px;
    margin-left: 10px;
`;

export const TariffCloseBlock = styled(TariffChangeBlock)`
    font-size: 16px;
`;

export const HideRouteTariffContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;
export const LowcostCharterContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;
export const Lowcost = styled(InlineTable)`
    color: #ffffff;
    width: 100px;
    border-radius: 4px;
    background-color: #4caf50;
    text-align: center;
`;
export const Charter = styled.div`
    color: #ffffff;
    width: 100px;
    border-radius: 4px;
    background-color: #67d457;
    text-align: center;
    margin-right: 5px;
`;

export const StyledHideTariffsButton = styled.div`
    width: 24px;
    height: 24px;
    background-image: url(${iconsOClose});
    background-repeat: no-repeat;
    background-size: 16px;
    background-position: center left;
    position: relative;
`;

export const RouteAdditionallyInfo = styled.div`
    border-top: none;
`;

export const SessionContainer = styled.div`
    padding-bottom: 6px;
`;

export const ProviderContainer = styled.div``;

export const ProviderBlock = styled.div`
    font-size: 14px;
`;

export const SortingContainer = styled.div`
    padding: 0 0 24px;
`;
